<template>
  <div style="padding:0 20px;">
    <h1>退款须知</h1>
    <p>为保障未成年人网络安全，本平台禁止未成年人充值打赏行为，若将手机交由未成年使用，建议您开启青少年模式。同时加强对手机支付方式的限制与管理。如遇未成年充值打赏、误充值、充值后反悔等问题，请在充值退款申诉入口按照提示逐一提交举证材料，一经核实通过，扣除可能产生的对应税费后，本平台将及时退还充值且尚未消费部分金额。提交申请后，当前账号的充值打赏功能将被禁止，同时，当前账号内的金币将在申请退款金额对应数量金币的范围内被回收。</p>
    <div class="title">未成年人充值后退款申请流程如下</div>
    <p>第一步:填写未成年监护人相关信息，包括:姓名，身份证号，身份证正反面照片</p>
    <p>第二步:上传监护人关系证明，如户口本照片、出生证明、其他关系证明材料(至少提供一项资料);填写情况说明(选填)</p>
    <p>第三步:输入监护人当前使用手机号并填写验证码</p>
    <p>第四步:选择需退款的时间范围和充值订单，填写监护人支付宝账号并上传充值支付账单(选填)</p>
    <p>第五步:签署《未成年人退款协议》,存在其他退款情形的，请根据实际情况选择理由并提交资料。</p>
    <div class="title">温馨提示</div>
    <p>1、未成年人退款流程仅支持对申请日前12个月的充值申请退款，对于超过12个月的充值支付账单，请直接联系客服咨询。针对主张误充值、充值反悔等要求退款的特殊情况，用户应当在付款日起七日内提出申请并按照平台流程提交相关材料，超期后本平台有权拒绝退款。</p>
    <p>2、若因用户的充值行为导致平台方产生税费的，平台方有权自行扣除应付税费部分后，再将未消费部分予以退款。</p>
    <p>3、为保证审核快速通过，请按照流程提交完整信息，审批通过后将退回到原支付账户或所提供的支付宝账户银行卡账户);请务必在七天内完成申请流程，如超过七天未提交，系统将自动关闭申请。</p>
    <p>4、如果微信、支付宝充值订单无法退回至原支付账户，系统会自动核查，将原路退回失败的部分再重新打款至提供的支付宝(银行卡)账号中。</p>
  </div>
</template>

<script>
// import {appActivityCreate} from "@/api/ranking";

export default {
  name: 'refund',
  data () {
    return {
      
    }
  },
  created() {
    
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1{
    font-size: 40px;
    text-align: center;
    margin:20px 0;
}
p{
    font-size: 28px;
    text-indent:56px;
    color: rgba(0,0,0,.8);
}
.title{
    font-size: 32px;
    color: #000;
    font-weight: bold;
    text-indent: unset;
    padding: 0;
    margin: 0;
    height: auto;
}
</style>
